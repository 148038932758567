<template>
	<div>
		<div style="margin: 10px 0">
			<el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
			<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
			<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
			<el-button class="ml-5" type="primary" @click="load">搜索</el-button>
			<el-button type="warning" @click="reset">重置</el-button>
		</div>

		<div style="margin: 10px 0">
			<el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
			<el-popconfirm class="ml-5" confirm-button-text='确定' cancel-button-text='我再想想' icon="el-icon-info"
				icon-color="red" title="您确定批量删除这些数据吗？" @confirm="delBatch">
				<el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
			</el-popconfirm>
<!-- 			<el-upload action="https://www.xjtljt.cc/user/import" :show-file-list="false" accept="xlsx"
				:on-success="handleExcelImportSuccess" style="display: inline-block">
				<el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
			</el-upload> -->
			<el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
		</div>

		<el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="id" label="ID" width="80"></el-table-column>
			<el-table-column prop="username" label="用户名" fixed="left"></el-table-column>
			<el-table-column prop="password" label="密码"></el-table-column>
			<el-table-column prop="nickname" label="姓名"></el-table-column>
			<el-table-column prop="email" label="邮箱"></el-table-column>
			<el-table-column prop="phone" label="电话"></el-table-column>
			<el-table-column prop="address" label="地址"></el-table-column>
			<el-table-column prop="createTime" label="创建时间"></el-table-column>
			<el-table-column prop="avatarUrl" label="头像"></el-table-column>
			<el-table-column prop="role" label="角色"></el-table-column>
			<el-table-column prop="gysname" label="供应商名称"></el-table-column>
			<el-table-column prop="tongyicode" label="同一社会信用代码"></el-table-column>
			<el-table-column prop="gysjc" label="供应商简称"></el-table-column>
			<el-table-column prop="qiyexingzhi" label="企业性质"></el-table-column>
			<el-table-column prop="farendaibiao" label="法人代表"></el-table-column>
			<el-table-column prop="farenphone" label="联系电话"></el-table-column>
			<el-table-column prop="zhuceziben" label="注册资本"></el-table-column>
			<el-table-column prop="qiyewangzhi" label="企业网址"></el-table-column>
			<el-table-column prop="yingyeqixian" label="营业期限"></el-table-column>
			<el-table-column prop="nashuirenzige" label="纳税人资格"></el-table-column>
			<el-table-column prop="nashuirensbh" label="纳税人识别号"></el-table-column>
			<el-table-column prop="gongsizuzhixingzhi" label="公司组织性质"></el-table-column>
			<el-table-column prop="jingyingfanwei" label="经营范围"></el-table-column>
			<el-table-column label="营业执照">
				<template slot-scope="scope">
					<el-button type="primary" @click="download(scope.row.yingyezhizhaoImg)">下载</el-button>
					<el-button type="primary" @click="preview(scope.row.yingyezhizhaoImg)">预览</el-button>
				</template>
			</el-table-column>
			<el-table-column label="道路运输许可证">
				<template slot-scope="scope">
					<el-button type="primary" @click="download(scope.row.daoluyunshuxkzImg)">下载</el-button>
					<el-button type="primary" @click="preview(scope.row.daoluyunshuxkzImg)">预览</el-button>
				</template>
			</el-table-column>
			<el-table-column label="企业标准化达标证书">
				<template slot-scope="scope">
					<el-button type="primary" @click="download(scope.row.qiyebiaozhunhuaFile)">下载</el-button>
					<el-button type="primary" @click="preview(scope.row.qiyebiaozhunhuaFile)">预览</el-button>
				</template>
			</el-table-column>
			<el-table-column label="人员车辆信息表">
				<template slot-scope="scope">
					<el-button type="primary" @click="download(scope.row.cheliangxinxibiaoFile)">下载</el-button>
					<el-button type="primary" @click="preview(scope.row.cheliangxinxibiaoFile)">预览</el-button>
				</template>
			</el-table-column>
			<el-table-column label="投标委托书">
				<template slot-scope="scope">
					<el-button type="primary" @click="download(scope.row.tbwts)">下载</el-button>
					<el-button type="primary" @click="preview(scope.row.tbwts)">预览</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="gongyingshangtype" label="供应商类型"></el-table-column>
			<el-table-column label="操作" width="280" align="center">
				<template slot-scope="scope">
					<el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
					<el-popconfirm class="ml-5" confirm-button-text='确定' cancel-button-text='我再想想' icon="el-icon-info"
						icon-color="red" title="您确定删除吗？" @confirm="del(scope.row.id)">
						<el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div style="padding: 10px 0">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
				:page-sizes="[2, 5, 10, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>

		<el-dialog title="信息" :visible.sync="dialogFormVisible" width="40%">
			<el-form label-width="80px" size="small">
				<el-form-item label="用户名">
					<el-input v-model="form.username" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="密码">
					<el-input v-model="form.password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="角色">
				  <el-select clearable v-model="form.role" placeholder="请选择角色" style="width: 100%">
				    <el-option v-for="item in roles" :key="item.name" :label="item.name" :value="item.flag"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="form.nickname" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="form.email" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="电话">
					<el-input v-model="form.phone" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="地址">
					<el-input v-model="form.address" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="创建时间">
					<el-date-picker v-model="form.createTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="头像">
					<el-input v-model="form.avatarUrl" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="角色">
					<el-input v-model="form.role" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="供应商名称">
					<el-input v-model="form.gysname" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="同一社会信用代码">
					<el-input v-model="form.tongyicode" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="供应商简称">
					<el-input v-model="form.gysjc" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="企业性质">
					<el-input v-model="form.qiyexingzhi" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="法人代表">
					<el-input v-model="form.farendaibiao" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input v-model="form.farenphone" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="注册资本">
					<el-input v-model="form.zhuceziben" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="企业网址">
					<el-input v-model="form.qiyewangzhi" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="营业期限">
					<el-input v-model="form.yingyeqixian" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="纳税人资格">
					<el-input v-model="form.nashuirenzige" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="纳税人识别号">
					<el-input v-model="form.nashuirensbh" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="公司组织性质">
					<el-input v-model="form.gongsizuzhixingzhi" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="经营范围">
					<el-input v-model="form.jingyingfanwei" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="营业执照">
					<el-upload action="https://www.xjtljt.cc:9091/file/upload" ref="file"
						:on-success="handleFileUploadSuccess">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="道路运输许可证">
					<el-input v-model="form.daoluyunshuxkzImg" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="企业标准化达标证书">
					<el-upload action="https://www.xjtljt.cc:9091/file/upload" ref="file"
						:on-success="handleFileUploadSuccess">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="人员车辆信息表">
					<el-input v-model="form.cheliangxinxibiaoFile" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="供应商类型">
					<el-select v-model="form.gongyingshangtype">
						<el-option  key="固体投标单位" value="固体投标单位"></el-option>
						<el-option  key="液体投标单位" value="液体投标单位"></el-option>
					</el-select>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "User",
		data() {
			return {
				tableData: [],
				total: 0,
				pageNum: 1,
				pageSize: 10,
				name: "",
				form: {},
				dialogFormVisible: false,
				multipleSelection: [],
				roles: []
			}
		},
		created() {
			this.load()
			this.request.get("/role").then(res => {
			  this.roles = res.data
			})
		},
		methods: {
			load() {
				this.request.get("/user/page", {
					params: {
						pageNum: this.pageNum,
						pageSize: this.pageSize,
						username: this.name,
					}
				}).then(res => {
					this.tableData = res.data.records
					this.total = res.data.total
				})
			},
			save() {
				this.request.post("/user", this.form).then(res => {
					if (res.code === '200') {
						this.$message.success("保存成功")
						this.dialogFormVisible = false
						this.load()
					} else {
						this.$message.error("保存失败")
					}
				})
			},
			handleAdd() {
				this.dialogFormVisible = true
				this.form = {}
				this.$nextTick(() => {
					this.$refs.img.clearFiles();
					this.$refs.file.clearFiles();
				})
			},
			handleEdit(row) {
				this.form = JSON.parse(JSON.stringify(row))
				this.dialogFormVisible = true
				this.$nextTick(() => {
					this.$refs.img.clearFiles();
					this.$refs.file.clearFiles();
				})
			},
			del(id) {
				this.request.delete("/user/" + id).then(res => {
					if (res.code === '200') {
						this.$message.success("删除成功")
						this.load()
					} else {
						this.$message.error("删除失败")
					}
				})
			},
			handleSelectionChange(val) {
				console.log(val)
				this.multipleSelection = val
			},
			delBatch() {
				let ids = this.multipleSelection.map(v => v.id) // [{}, {}, {}] => [1,2,3]
				this.request.post("/user/del/batch", ids).then(res => {
					if (res.code === '200') {
						this.$message.success("批量删除成功")
						this.load()
					} else {
						this.$message.error("批量删除失败")
					}
				})
			},
			reset() {
				this.name = ""
				this.load()
			},
			handleSizeChange(pageSize) {
				console.log(pageSize)
				this.pageSize = pageSize
				this.load()
			},
			handleCurrentChange(pageNum) {
				console.log(pageNum)
				this.pageNum = pageNum
				this.load()
			},
			handleFileUploadSuccess(res) {
				this.form.file = res
			},
			handleImgUploadSuccess(res) {
				this.form.img = res
			},
			download(url) {
				window.open(url)
			},
			exp() {
				var datas = []
				if (this.tableData.length === 0) {
					this.$message.warning('没有数据可导出');
					return;
				} else {
					this.handelExcel()
				}
			},
			handleExcelImportSuccess() {
				this.$message.success("导入成功")
				this.load()
			},
			preview(url) {
				window.open('https://file.keking.cn/onlinePreview?url=' + encodeURIComponent(window.btoa((url))))
			},
			handelExcel() {
				require.ensure([], () => {
					let index = 0
					const {
						export_json_to_excel
					} = require('@/vendor/Export2Excel.js') //注意这个Export2Excel路径
					const tHeader = ['用户名','姓名','邮箱', '电话',  '地址', '供应商名称', '统一社会信用代码', '企业性质', '法人代表',  '联系电话','注册资本',
						'企业网址', '营业期限','纳税人资格','纳税人识别号','公司组织性质','经营范围','营业执照','道路运输许可证','企业标准化达标证书','人员车辆信息表','投标委托书','供应商类型'
					] // 上面设置Excel的表格第一行的标题
					const filterVal = ['username','nickname','email', 'phone',  'address', 'gysjc', 'tongyicode', 'qiyexingzhi', 'farendaibiao',  'farenphone','zhuceziben',
						'qiyewangzhi', 'yingyeqixian','nashuirenzige','nashuirensbh','gongsizuzhixingzhi','jingyingfanwei','yingyezhizhaoImg','daoluyunshuxkzImg','qiyebiaozhunhuaImg','cheliangxinxibiaoFile','tbwts','gongyingshangtype'
					] // tableData里对象的属性key值
					const list = this.tableData //把要导出的数据tableData存到list
					const data = this.formatJson(filterVal, list)
					export_json_to_excel(tHeader, data, '企业资料信息' + Date.now())
				})
			},
			//格式转换,不需要改动
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => v[j]))
			},
		}
	}
</script>


<style>
	.headerBg {
		background: #eee !important;
	}
</style>
